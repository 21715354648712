import React from "react"
import SeoHead from "../../components/global/seoHead"

import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import BeebomMain from "../../components/branding/beebom/beebomMain"
import BeebomFullwidth from "../../components/branding/beebom/beebomFullwidth"
import BeebomMoreBranding from "../../components/branding/beebom/beebomMoreBranding"

import brandingFeaturedImage from "../../../static/branding/beebom/beebom-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/beebom.scss"


export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Beebom: Digital Brand Transformation | WowMakers"
            description="Discover how WowMakers revitalized Beebom's digital presence. Our branding expertise brought their tech vision to life."
        />
    </>
)

const beeboomBranding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-beebom"
                bclass="case-studies-page branding"
                >
            </Seo>
            <ScrollIndicationHeightZero />
            <BeebomMain />
            <BeebomFullwidth />
            <BeebomMoreBranding />
        </Layout>
    )
}

export default beeboomBranding
